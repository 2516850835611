function boom() {
  const tabs = document.querySelectorAll(".bowe-tabs");

  if (!tabs) return;

  const activeToken = "active-tab";

  tabs.forEach((tab) => {
    const tabMenu = tab.querySelector(".bowe-tabs-menu"); // Get the first menu
    const buttons = tabMenu?.querySelectorAll("li") || []; // Fixed typo
    const items = tab.querySelectorAll(":scope > .bowe-tabs-content > .bowe-tabs-tab");

    // Listen on tab menu button click.
    buttons.forEach((button, index) => {
      //const lis = tabMenu?.querySelectorAll("li") || []; // Fixed typo

      button.addEventListener("click", (e) => {
        e.preventDefault();

        // return if already activated
        if (button.classList.contains(activeToken)) {
          return;
        }

        //Remove iniatl class
        tab.classList.remove("active-tab-1");

        // Remove active class from all buttons & tab content
        buttons.forEach((btn) => btn.classList.remove(activeToken));
        items.forEach((item) => item.classList.remove(activeToken));

        // Add active class to the clicked button and corresponding tab content
        button.classList.add(activeToken);
        items[index]?.classList.add(activeToken); // Ensure index exists

        console.log("Activated Tab:", items[index]);
      });
    });

    // 💡 FAQ Mode: Allow multiple tabs to open on mobile if `.faq-mobile` is present
    function enableFaqMode() {
      if (window.innerWidth <= 777 && tab.classList.contains("faq-tabs-mobile")) {
        items.forEach((item) => {
          let trigger = item.querySelector(".bowe-tabs-tab-faq-header");
          trigger.addEventListener("click", () => {
            item.classList.toggle("is-open"); // Toggle open state
          });
        });
      }
    }

    // Run FAQ mode setup on load and on resize
    enableFaqMode();
    window.addEventListener("resize", enableFaqMode);
  });
}

function animation() {
  const tabsBlocks = document.querySelectorAll(".bowe-tabs.styled");

  if (!tabsBlocks) return;

  const tl = gsap.timeline();

  tabsBlocks.forEach((tabsBlock) => {
    // Get necessary elements
    const navWrapper = tabsBlock.querySelector(".bowe-tabs-menu .styled-inner");
    const navWrapperRect = navWrapper.getBoundingClientRect();

    const nav = tabsBlock.querySelectorAll(".bowe-tabs-menu li");
    const floatingBg = tabsBlock.querySelector(".styled-bg");
    const activeTab = tabsBlock.querySelector(".bowe-tabs-menu li.active-tab");

    const offSetWidth = 0;

    // Set initial position and width of the floatingBg
    if (activeTab) {
      const activeTabRect = activeTab.getBoundingClientRect();

      let el_width = activeTab.offsetWidth + offSetWidth;
      let offset_left = activeTabRect.left - navWrapperRect.left;

      gsap.set(floatingBg, { width: el_width, x: offset_left });
      //setInitialPositionAndWidth(activeTab, navWrapper, floatingBg);
    }

    // Add click event listener to each tab
    nav.forEach((tab) => {
      tab.addEventListener("click", () => {
        const navWrapperRect = navWrapper.getBoundingClientRect();
        const tabClicked = tab.getBoundingClientRect();
        let el_width = tab.offsetWidth + (tab.classList.contains("last") ? 0 : offSetWidth);
        let offset_left = tabClicked.left - navWrapperRect.left;
        tl.clear();
        tl.to(floatingBg, 0.5, { width: el_width, x: offset_left, ease: "power1.out" });
      });
    });
  });
}

document.addEventListener("DOMContentLoaded", function () {
  boom();
  animation();
});

/*
/**
     * Tab menu button click listener.
     *
     * @param index
     
function handleTabChange(index) {
  const button = buttons[index];
  const item = items[index];

  console.log(item);

  // return if already activated
  if (button.classList.contains(activeToken)) {
    return;
  }
  // Remove active class.
  const activeTabs = tabMenu?.querySelectorAll(`li.${activeToken}`) || [];
  const activeItems = tab.querySelector(`:scope > .bowe-tabs-content`);

  console.log(activeItems, items);
  return;
  [...activeTabs, ...activeItems]?.forEach((item) => item.classList.remove(activeToken));

  // add active class.
  button.parentNode.classList.add(activeToken);
  item.classList.add(activeToken);
}
  */
